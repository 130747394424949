
.aboutLink, .homeLink{
    text-decoration: none;
    color: rgba(0,0,0,.5);

}


.aboutLink:hover, .homeLink:hover{
    text-decoration: none;
    color: rgba(0,0,0,0.7);
}