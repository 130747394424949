

.link-list-container{
    width: 50%;
    margin: auto;
    border-radius: 1.27rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    padding: 0 10px;
    margin-bottom: 10px;
}
.link-logo{
    width: 40px;
}

.link-logo > img {
    width: 100%;
}

.left-link, .right-link{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.userName > p {
    margin: 0;
}

@media screen and (max-width: 1000px) {
    .link-list-container{
        width: 60%;
    }
}
@media screen and (max-width: 768px) {
    .link-list-container{
        width: 80%;
    }
}
@media screen and (max-width: 576PX) {
    .link-list-container{
        width: 80%;
    }
}
@media screen and (max-width: 400PX) {
    .link-list-container{
        width: 100%;
    }
}