.edit-modal{
    background-color: black;
    width: 150%;
   
    text-align: right;
    margin-left: -25%;
}
.accountModal-form{
    border-radius: 1.27rem;
}
.accountModal-bg-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* overflow: hidden;
    object-fit: inherit; */
}
.fa-x{
    cursor: pointer;
    font-size: large;

}

.accountModal-bg{
    /* width: 100vw;
   min-height: 300px;
    background-image: url('../../../assets/img1.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    /* object-fit: cover; */
    object-position: center;
    width: 100%;
    height: 250px;
    position: relative;

}


.accountModal-contact-details{
    background-color: white;
    width: 90%;
    position: relative;
    height: auto;
    border-radius: 1.27rem;
    margin: 0 auto;
    margin-top: 0;
    text-align: center;
    z-index: 1;

}
/* .profile-pic-accountModal{
    display: flex;
    justify-content: center;
    align-items: center;
} */
.profile-pic-accountModal {
    
    margin: auto;
    width: 10rem;
    border: none; 
    height: 10rem;
    border-radius: 5rem ;
    text-align: center;
    object-position: center;
    object-fit: contain;
    background-color: white;
}



.grid{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: center;
}

.accountModal-fname,.accountModal-lname,.accountModal-title,.accountModal-phone1, .accountModal-phone2, .accountModal-email,.accountModal-email2{
    width: 95%;
    margin-bottom: 30px;
    padding: 1rem 1rem 1rem 3rem;
    font-family: poppins;
    font-size: 1.25rem;
    border: none;
    outline: none;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
}
.accountModal-description{
    width: 95%;
    margin-bottom: 30px;
    padding: 1rem 1rem 1rem 3rem;
    font-family: poppins;
    font-size: 1.25rem;
    border: none;
    overflow: hidden;
    outline: none;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */
.grid-row-6{
    grid-column: 1 / 3;
}
.accountModal-address{
    width: 95%;
    margin: auto auto;
    margin-bottom: 30px;
    padding: .5rem 1rem 0.5rem 6rem;
    font-family: poppins;
    font-size: .8rem;
    border: none;
    grid-column: 1 / span 2;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    color: grey;
    
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
}
.accountModal-address > label {
    margin: 0;
}
.accountModal-address-detail, .country{
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
    width: 90%;
    height: 20px;
    margin-bottom: 5px;
}

.postal-city{
    display: flex;
    gap: 10px;
    text-align: left;
}

.postal-code, .city {
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
    width: 80%;
    height: 20px;

    margin-bottom: 5px;
}




.accountModal-icon-description {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
    font-size: 22px;
    color: #524e52;
}
.accountModal-address-icon {
    position: absolute;
    top: 4rem;
    left: 3rem;
    font-size: 22px;
    color: #524e52;
}
.accountModal-description::placeholder{
    padding-top: 1%;
}
div.icon-accountModal-container{
    color: white;
    width: 100%;
    position: relative;
   

}

.accountModal-label {
    position: absolute;
    font-weight: 600;
    /* width: 90px; */
    border-left: 4px  solid white;
    border-right: 4px  solid white;
    font-size: 14px;
    color: rgb(76, 74, 74);
    height: 25px;
    background-color: rgb(255, 255, 255);
    left: 30px;
    top: -12px;
}
.accountModal-icon {
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    font-size: 22px;
    color: #524e52;
}

.accountModal-save-btn{
    width: 90%;
    padding-top: 1.25rem ;
    padding-bottom: 1.25rem ;
    border-radius: 50px;
    color: white;
    cursor: pointer;
    font-size: large;
    font-weight: bold;
    border: none;
    margin-bottom: 20px;
    background-image: linear-gradient(to right,#12334f, #224f8e, #692363);
}

.accountModal-save-btn:hover{
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.59);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.59);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.59);
}

@media screen and (max-width: 650px) {
    .edit-modal{
        width: 100%;
        margin-left: 0;
    }
    .accountModal-fname,.accountModal-lname,.accountModal-title,.accountModal-phone1, .accountModal-description, .accountModal-phone2, .accountModal-email,.accountModal-email2{
        
        font-size: 1rem;
      
    }
}
@media screen and (max-width: 576px) {
    .edit-modal{
        width: 100%;
        margin-left: 0;
    }
    .accountModal-fname,.accountModal-lname,.accountModal-title,.accountModal-phone1, .accountModal-description, .accountModal-phone2, .accountModal-email,.accountModal-email2{
        
        font-size: 1rem;
      
    }
}



@media screen and (max-width: 450px) {
    .edit-modal{
        background-color: black;
        width: 100%;
        margin-left: 0;
    }
    .accountModal-fname,.accountModal-lname,.accountModal-title,.accountModal-phone1, .accountModal-description, .accountModal-phone2, .accountModal-email,.accountModal-email2{
        
        font-size: 1rem;
      
    }
.grid{
    grid-template-columns: 100%;
    
}
.row-2{
    grid-row: 2 / 3;
    font-size: 0.8rem;
}
.row-3{
    grid-row: 3 / 4;
}
.row-4{
    grid-row: 4 / 5;
}
.row-5{
    grid-row: 5 / 6;

}
.row-6{
    grid-row: 6 / 7;
}
.row-7{
    grid-row: 7 / 8;
}
.row-8{
    grid-row: 8 / 9;
}

.accountModal-contact-details{
    font-size: 0.8rem;
}

}