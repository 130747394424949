.links-main-container{
    width: 50%;
    border-radius: 15px;
    height: 300px;
    padding-top: 20px;
    overflow: auto;
    background: white;
    margin: 10px auto;
    text-align: center;
    /* -ms-overflow-style: none;   IE and Edge */
  /* scrollbar-width: none;  */
}
/* width */
.links-main-container::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .links-main-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
  }
   
  /* Handle */
  .links-main-container::-webkit-scrollbar-thumb {
    background: rgba(86, 86, 86, 0.2); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .links-main-container::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }
.links-container{
    width: 50%;
    margin: auto;
    text-align: center;
}

.links-header{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    flex-shrink: 1;
}

.add-links-btn{
    padding: .5rem 3rem .5rem 3rem;
    background-color:#e2d1ff;
    color: #295da6;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    
} 
.add-links-btn:hover{
    color: #eee;
    border: 1px solid #e2d1ff ;
    background-image: linear-gradient(to right,#12334f, #224f8e, #692363);
}



.links-added{
    margin-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 800px) {
    .links-main-container{
        width: 90%;
    }
}


@media screen and (max-width: 1200px) {
    .add-links-btn{
        padding: .5rem 2rem;
        
    }
    
    .link-list-container{
        width: 100%;
        margin: auto;
        padding: 0 10px;
        text-align: center;
    }
}
@media screen and (max-width: 1000px) {
    .add-links-btn{
        padding: .5rem 2rem;
        
    }
    
    .links-container{
        width: 60%;
        margin: auto;
        padding: 0 10px;
        text-align: center;
    }
}
@media screen and (max-width: 768px) {
    .add-links-btn{
        padding: .5rem 2rem;
        
    }
    
    .links-container{
        width: 80%;
        margin: auto;
        padding: 0 10px;
        text-align: center;
    }
}

@media screen and (max-width: 576px) {
    .add-links-btn{
        padding: .5rem .8rem;
        font-size: 1rem;
    }
    
    .links-container{
        width: 80%;
        margin: auto;
        padding: 0 10px;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .add-links-btn{
        padding: .3rem .5rem;
        font-size: .9rem;
    }
    .links-container{
        width: 100%;
        margin: 0;
        padding: 0 10px;
        text-align: center;
    }
}