



.hero{
    background-color: black;
    width: 100vw;
    height: 100vh;
    flex: 1;
}
.homepage-container{
    width: 100vw;
    height: auto;
    background-color: black;
    text-align: center;
}
.mainContainer{
    margin-top: 200px;
}
.bg-img{
    /* width: 100vw;
   min-height: 300px;
    background-image: url('../../../assets/img1.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    object-fit: contain;
    width: 100%;
    
    height: 90vh;
    overflow: hidden;
}

.bg-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-container{
    background-color: white;
    width: 50%;
    border-radius: 15px;
    position: relative;
    height: 65%;
    padding-bottom: 20px;
    margin: 0 auto;
    /* margin-top: -600px; */
    z-index: 1;
}

.profile-pic-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.profile-pic {
    margin-top: -150px;
    width: 10rem;
    border: none; 
    height: 10rem;
    border-radius: 5rem ;
    text-align: center;
    object-position: center;
    object-fit: contain;
    background: white;
}
.user-name{
    margin-top: 10px;
}
.contact-details-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    width: 100%;

}
.edit-form-btn{
    height: 100px;
}
.edit-btn{
    padding: .5rem 3rem .5rem 3rem;
    background-color:#e2d1ff;
    color: #295da6;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.47);
    margin: 30px 0;
} 
.edit-btn:hover{
    color: #eee;
    border: 1px solid #e2d1ff ;
    background-image: linear-gradient(to right,#12334f, #224f8e, #692363);
}

@media screen and (max-width: 800px) {
    .form-container{
        width: 90%;
    }
}