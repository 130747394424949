.about-container{
    position: relative;
    padding: 20px;
}

.about-footer{
    position: absolute;
    
    bottom: 0;
    background-color: white;
    text-align: center;
    width: 100%;
    color: black;
}