.signup-main-container{
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 350px;
    align-items: center;
    background-color: black;
    /* top: 15%;
    left: 40%;
    position: absolute; */

}   

.signup-logo {
    width: 300px;
    margin-bottom: 40px;
    /* position: absolute;
    top: 0; */
}

.signup-logo > img {
    width: 100%;
}

.signup-fname,.signup-lname,.signup-uname,.signup-cf-password,.signup-email, .signup-password{
    width: 100%;
    margin-bottom: 30px;
    padding: 1rem 1rem 1rem 3rem;
    font-family: poppins;
    font-size: 1.25rem;
    border: none;
    border-radius: 50px;
}
div.icon-signup-container{
    color: white;
    width: 100%;
    position: relative;
   

}
.signup-icon {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
    font-size: 22px;
    color: #524e52;
}
/* .email::placeholder, .password::placeholder {
   padding-left: 2.5rem;
   padding-right: 1.5rem;
} */

.signup-btn{
    width: 100%;
    padding-top: 1.25rem ;
    padding-bottom: 1.25rem ;
    border-radius: 50px;
    color: white;
    cursor: pointer;
    font-size: large;
    border: none;
    outline: none;
    font-weight: bold;
    margin-bottom: 20px;
    background-image: linear-gradient(to right,#12334f, #224f8e, #692363);
}

.reset-password, .sign-up-link, .sign-up-link > span > a{
    color: white;
    margin-top: 10px;
    text-decoration: none;
    font-size: 14px;
}
.sign-up-link > span {
    font-weight: bold;
}

@media  screen and (max-width: 400px) {
    .signup-logo{
        margin-top: 50px;
        margin-bottom: 15px;
    }
    .signup-fname,.signup-lname,.signup-uname,.signup-cf-password,.signup-email, .signup-password{
        margin-bottom: 15px;
    }
}