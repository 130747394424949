*{
  margin: 0;
  padding: 0;
  
  font-family: poppins, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow: hidden;
  padding: 0;
 background-color: black;
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.main-modal{
  margin-top: 10%;
}

.all-links-profile-pic{
  width: 150px;
  margin: auto;
  margin-bottom: 20px;
 
}
.all-links-logo{
  width: 100%;
}

.all-links-form{
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 20px;
}

.socialModal-name, .socialModal-link{
  position: relative;
  width: 100%;
}

.socialModal-label{
  position: absolute;
    font-weight: 600;
    /* width: 90px; */
    border-left: 4px  solid white;
    border-right: 4px  solid white;
    font-size: 14px;
    color: rgb(76, 74, 74);
    height: 25px;
    background-color: rgb(255, 255, 255);
    left: 30px;
    top: -12px;
}
.all-links-name
{ 
  width: 100%;
  padding: 1rem 1rem 1rem 1.5rem;
  font-family: poppins;
  font-size: 1rem;
  border: none;
  outline: none;
  border-radius: 50px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.59);
}

.all-link-btn{
  
      width: 90%;
      padding-top: 1rem ;
      padding-bottom: 1rem ;
      border-radius: 50px;
      color: white;
      cursor: pointer;
      font-size: large;
      font-weight: bold;
      border: none;
      background-image: linear-gradient(to right,#12334f, #224f8e, #692363);
}
  
  .all-link-btn:hover{
      box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.59);
      -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.59);
      -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.59);
  }
