.links-modal-container{
    margin-top: 10%;
}

.links-logo-container{
    width: 100%;
}

.links-main{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: fit-content;
   color: black;

}

.image-container{
    width: 80px;
    height: 80px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.47);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.47);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.47);
}

 .image-container > img {
    
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
}